import React from "react";
import { FilterComponent } from "../componets/FilterComponent";
import { StoreContextConsumer } from "../StoreContext";
import { FullFixedReport } from "../componets/FullFixedReport";

export class FullFixedPage extends React.Component {
    render() {
        return (
            <>
                <div className="page-head">Financial Report</div>
                <div className="p-grid p-fluid">
                    <div className="p-col-12 ">
                        <StoreContextConsumer>
                            {({ terminal, setTerminal, clerk, setClerk }) => {
                                return (
                                    <FilterComponent terminal={terminal} setTerminal={setTerminal} clerk={clerk} setClerk={setClerk} />
                                );
                            }}
                        </StoreContextConsumer>
                    </div>

                    <div className="p-col-12 ">
                        <StoreContextConsumer>
                            {({ currentStore, dateRange, clerk, terminal }) => {
                                return (
                                    currentStore && <FullFixedReport store={currentStore} dates={dateRange} clerk={clerk} terminal={terminal} />
                                );
                            }}
                        </StoreContextConsumer>
                    </div>

                </div>
            </>
        );
    }
}
