import React, { Component } from "react";
import apiClient from "../apiClient";
import { Card } from "primereact/card";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment'

export class CustomerReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true,
            first: 0,
            rows: 25,
            totalRecords: 0
        };
        this.onPage = this.onPage.bind(this)
    }

    onPage(event) {
        this.setState({ first: event.first, rows: event.rows, })
    }
    fetchData(from, to) {
        this.setState({ loading: true });
        const fromDate = moment(this.props.dates[0]).format('YYYY-MM-DD')
        const toDate = moment(this.props.dates[1]).format('YYYY-MM-DD')
        const clerkId = this.props.clerk.id;
        const terminalId = this.props.terminal.code;
        apiClient
            // customerReport api
            .get(
                `v1/report/customer/clerk=${clerkId}/terminal=${terminalId}/from_date=${fromDate}/to_date=${toDate}/limit_offset=${from}/limit_row=${to}`
            )
            .then(res => {
                this.setState({ data: res.data.values, loading: false });
            })
            .catch(err => {
                this.setState({ data: [], loading: false });
                console.log('Error fetching customer data: ', err)
            });
    }

    componentDidMount() {
        this.setState(() => ({ totalRecords: this.props.totalCount[9].memory }),
            () => this.fetchData(this.state.first, this.state.first + this.state.rows))
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.props.dates[1] === null) || (this.props.store === null)) return;
        if ((this.props.dates !== prevProps.dates) || (this.props.clerk !== prevProps.clerk) || (this.props.terminal !== prevProps.terminal)) {
            this.setState({ first: 0 })
            this.fetchData(0, this.state.rows)
        }
        if (this.props.store !== prevProps.store) {
            this.setState({ first: 0, totalRecords: this.props.totalCount[9].memory })
            this.fetchData(0, this.state.rows)
        }
        if ((this.state.first !== prevState.first) || (this.state.rows !== prevState.rows)) {
            this.fetchData(this.state.first, this.state.first + this.state.rows)
        }
    }

    nameTemplate(rowData, column) {
        return <span>{rowData.fname} {rowData.sname}</span>;
    }

    addressTemplate(rowData, column) {
        return <span>{rowData.address1} {rowData.address2} {rowData.address3} {rowData.postcode}</span>
    }

    render() {
        return (
            <React.Fragment>

                <Card title="Customer Report" style={{ marginTop: "25px" }}>
                    {this.state.loading ? (
                        <div>Loading...</div>
                    ) :
                        (
                            <div>
                                <div> Showing records  from <b>{this.state.first + 1}</b> to <b>{this.state.first + this.state.rows}</b> </div>
                                <div style={{ textAlign: 'right', marginBottom: '10px' }}>
                                    <span style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }} onClick={(e) => this.dataTable.exportCSV()}>Download CSV</span>
                                </div>
                                <DataTable ref={(el) => { this.dataTable = el; }} value={this.state.data}
                                    paginator={true} rows={this.state.rows} totalRecords={this.state.totalRecords}
                                    lazy={true} first={this.state.first} onPage={this.onPage} loading={this.state.loading}
                                    resizableColumns={true} columnResizeMode="fit"
                                    rowsPerPageOptions={[25, 50, 100, 200]} scrollable={true} scrollHeight='400px'
                                    paginatorPosition='both'>

                                    <Column field="id" header="ID" bodyStyle={{ textAlign: 'center' }} style={{ width: '75px' }} />
                                    <Column field="keycode" header="Key Code" style={{ width: '200px' }} />
                                    <Column field="fname" header=" Name" body={this.nameTemplate} style={{ width: '250px' }} />
                                    <Column field="address1" header="Address" body={this.addressTemplate} style={{ width: '250px' }} />
                                    <Column field="qty" header="Quantity" bodyStyle={{ textAlign: 'right' }} style={{ width: '75px' }} />
                                    <Column field="value" header="Amount" body={(rowData, column) => <span> {rowData.value.toFixed(2)}</span>} bodyStyle={{ textAlign: 'right' }} style={{ width: '75px' }} />

                                </DataTable>
                            </div>
                        )}
                </Card>
            </React.Fragment>
        );
    }
}
