import React, { Component } from "react";
import { StoreContextConsumer } from "../StoreContext";
import { FilterComponent } from "../componets/FilterComponent"
import { GroupSalesAmountChart } from "../componets/GroupSalesAmountChart";
import { HourSalesGrossChart } from "../componets/HourSalesGrossChart";
import { GrossNet } from "../componets/GrossNet";
import { Transaction } from "../componets/Transaction";
import { DeptSalesChartAmount } from "../componets/DeptChartAmount";
import { GrossNetChart } from "../componets/GrossNetChart";
import { PluChart } from "../componets/PluChart";

export class Dashboard extends Component {
  render() {
    return (
      <>
        <div className="page-head">Dashboard</div>
        <div className="p-grid p-fluid">

          <div className="p-col-12 ">
            <StoreContextConsumer>
              {({ terminal, setTerminal, clerk, setClerk }) => {
                return (
                  <FilterComponent terminal={terminal} setTerminal={setTerminal} clerk={clerk} setClerk={setClerk} />
                );
              }}
            </StoreContextConsumer>
          </div>
        </div>

        <div className="p-grid p-fluid ">
          <div className="p-col-12 p-lg-6">
            <StoreContextConsumer>
              {({ currentStore, dateRange, clerk, terminal, }) => {
                return (
                  currentStore && <GrossNet store={currentStore} dates={dateRange} clerk={clerk} terminal={terminal} />
                );
              }}
            </StoreContextConsumer>
          </div>

          <div className="p-col-12 p-lg-6">
            <StoreContextConsumer>
              {({ currentStore, dateRange, clerk, terminal, }) => {
                return (
                  currentStore && <GrossNetChart store={currentStore} dates={dateRange} clerk={clerk} terminal={terminal} />
                );
              }}
            </StoreContextConsumer>
          </div>
        </div>

        <div className="p-grid p-fluid">
          <div className="p-col-12 p-lg-6">
            <StoreContextConsumer>
              {({ currentStore, dateRange, clerk, terminal }) => {
                return (
                  currentStore && <DeptSalesChartAmount store={currentStore} dates={dateRange} clerk={clerk} terminal={terminal} />
                );
              }}
            </StoreContextConsumer>
          </div>

          <div className="p-col-12 p-lg-6">
            <StoreContextConsumer>
              {({ currentStore, dateRange, clerk, terminal }) => {
                return (
                  currentStore && <GroupSalesAmountChart store={currentStore} dates={dateRange} clerk={clerk} terminal={terminal} />
                );
              }}
            </StoreContextConsumer>
          </div>
        </div>

        {/* <div className="p-grid p-fluid">
          <div className="p-col-12 p-lg-12">
            <StoreContextConsumer>
              {({ currentStore, dateRange, clerk, terminal, totalCount }) => {
                return totalCount.length !== 0 && (
                  currentStore && <PluChart store={currentStore} dates={dateRange} clerk={clerk} terminal={terminal} totalCount={totalCount} />
                );
              }}
            </StoreContextConsumer>
          </div>


          <div className="p-col-12 p-lg-12">
            <StoreContextConsumer>
              {({ currentStore, dateRange, clerk, terminal }) => {
                return (
                  currentStore && <HourSalesGrossChart store={currentStore} dates={dateRange} clerk={clerk} terminal={terminal} />
                );
              }}
            </StoreContextConsumer>
          </div>
        </div> */}

        {/* <div className="p-grid p-fluid">
          <div className="p-col-12 ">
            <StoreContextConsumer>
              {({ currentStore, dateRange, clerk, terminal }) => {
                return (
                  currentStore && <Transaction store={currentStore} dates={dateRange} clerk={clerk} terminal={terminal} />
                );
              }}
            </StoreContextConsumer>
          </div>
        </div> */}

      </>
    );
  }
}
