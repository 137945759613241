import React from "react";
import apiClient from "../apiClient";
import { Card } from "primereact/card";
import moment from 'moment'

export class GrossNet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: false
        };
    }

    fetchData() {
        this.setState({ loading: true });
        const fromDate = moment(this.props.dates[0]).format('YYYY-MM-DD')
        const toDate = moment(this.props.dates[1]).format('YYYY-MM-DD')
        const clerkId = this.props.clerk.id;
        const terminalId = this.props.terminal.code;
        apiClient
            .get(
                `v1/report/fixedtotaliser/rec=2/clerk=${clerkId}/terminal=${terminalId}/from_date=${fromDate}/to_date=${toDate}`
                //short fixed totalizer
            )
            .then(res => {
                this.setState({ data: res.data.values, loading: false });
            })
            .catch(err => {
                this.setState({ data: [], loading: false });
                console.log('Error fetching GrossNet data: ', err)
            });
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if ((this.props.dates[1] == null) || (this.props.store == null)) return;
        if ((this.props.dates !== prevProps.dates) || (this.props.store !== prevProps.store) || (this.props.clerk !== prevProps.clerk) || (this.props.terminal !== prevProps.terminal)) {
            this.fetchData();
        }
    }

    render() {
        const items = this.state.data
        return (
            <>
                <Card style={{ marginTop: "25px", height: '285px' }} >
                    {this.state.loading ? (
                        <div>Loading...</div>
                    ) : this.state.data.length === 0 ? (
                        <div>No data available</div>
                    ) : (
                                <>
                                    <div className="p-grid p-align-end"  >
                                        <div className="p-col">
                                            <i className="pi pi-chart-line" style={{ 'fontSize': '4em', paddingTop: '.25em', color: '#999' }}></i>
                                        </div>
                                        <div className="p-col">
                                            <p>Gross Sales</p>
                                            <h1><b>£{items.gross[1].toFixed(2)}</b></h1>
                                        </div>
                                        <div className="p-col">
                                            <p>Quantity</p>
                                            <h1>{items.gross[0]}</h1>
                                        </div>
                                    </div>
                                    <div className="p-grid p-align-center" style={{ marginTop: '40px' }} >
                                        <div className="p-col">
                                            <i className="pi pi-chart-bar" style={{ 'fontSize': '4em', paddingTop: '.25em', color: '#999' }}></i>
                                        </div>
                                        <div className="p-col">
                                            <p>Net Sales</p>
                                            <h1><b>£{items.net[1].toFixed(2)}</b></h1>
                                        </div>
                                        <div className="p-col">
                                            <p>Quantity</p>
                                            <h1>{items.net[0]}</h1>
                                        </div>
                                    </div>
                                </>
                            )}
                </Card>
            </>
        )
    }
}
