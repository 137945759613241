import React from "react";
import apiClient from "../apiClient";
import { Card } from "primereact/card";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import moment from 'moment'

export class HourSalesReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false

    };
  }

  fetchData() {
    this.setState({ loading: true });
    const fromDate = moment(this.props.dates[0]).format('YYYY-MM-DD')
    const toDate = moment(this.props.dates[1]).format('YYYY-MM-DD')
    const clerkId = this.props.clerk.id;
    const terminalId = this.props.terminal.code;
    // HourSalesReportApi
    apiClient
      .get(
        `/v1/report/hourlysales/clerk=${clerkId}/terminal=${terminalId}/from_date=${fromDate}/to_date=${toDate}`
      )
      .then(res => {
        let time = 0
        let data = res.data.values.map(item => { item['hour'] = [time, time + 1]; time += 1; return item })
        this.setState({ data: data, loading: false });
      })
      .catch(err => {
        this.setState({ data: [], loading: false });
        console.log('Error fetching HourlySales data: ', err)
      });
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if ((this.props.dates[1] == null) || (this.props.store == null)) return;
    if ((this.props.dates !== prevProps.dates) || (this.props.store !== prevProps.store) || (this.props.clerk !== prevProps.clerk) || (this.props.terminal !== prevProps.terminal)) {
      this.fetchData();
    }
  }

  hourTemplate(rowData, column) {
    return <span>{moment(rowData.hour, 'HH:mm').format('HH-mm')}</span>;
  }

  render() {
    let headerGroup = <ColumnGroup>
      <Row>
        <Column header="Hour" rowSpan={3} style={{ width: '5%' }} />
        <Column header="Gross" colSpan={2} style={{ width: '20%' }} />
        <Column header="Net" colSpan={2} style={{ width: '20%' }} />
      </Row>
      <Row>
        <Column header="Quantity" />
        <Column header="Amount" />
        <Column header="Quantity" />
        <Column header="Amount" />
      </Row>
    </ColumnGroup>;

    return (
      <>
        <Card title="Hourly Sales Report" style={{ marginTop: "25px" }}>
          {this.state.loading ? (
            <div>Loading...</div>
          ) : this.state.data.length === 0 ? (
            <div>No data available, select different date range or store</div>
          ) : (
                <div>
                  <div style={{ textAlign: 'right', marginBottom: '10px' }}>
                    <span style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }} onClick={(e) => this.dataTable.exportCSV()}>Download CSV</span>
                  </div>

                  <DataTable ref={(el) => { this.dataTable = el; }} value={this.state.data} headerColumnGroup={headerGroup} resizableColumns={true} columnResizeMode="fit" >
                    <Column field='hour' bodyStyle={{ textAlign: 'center' }} body={this.hourTemplate} />
                    <Column field="gross" body={(rowData, column) => <span>{rowData.gross[0]}</span>} bodyStyle={{ textAlign: 'right' }} />
                    <Column field="gross" body={(rowData, column) => <span> {rowData.gross[1].toFixed(2)}</span>} bodyStyle={{ textAlign: 'right' }} />
                    <Column field="net" body={(rowData, column) => <span>{rowData.net[0]}</span>} bodyStyle={{ textAlign: 'right' }} />
                    <Column field="net" body={(rowData, column) => <span>{rowData.net[1].toFixed(2)}</span>} bodyStyle={{ textAlign: 'right' }} />
                  </DataTable>

                </div>
              )}
        </Card>
      </>
    );
  }
}

