import React from "react";
import { StoreContextConsumer } from "../StoreContext";
import { FilterComponent } from "../componets/FilterComponent";
import { HourSalesReport } from "../componets/HourSalesReport";
import { HourSalesLineChart } from "../componets/HourSalesLineChart";

export class HourSalesReportPage extends React.Component {
    render() {
        return (
            <>
                <div className="page-head">Hourly Sales Report </div>

                <StoreContextConsumer>
                    {({ terminal, setTerminal, clerk, setClerk }) => {
                        return (
                            <FilterComponent terminal={terminal} setTerminal={setTerminal} clerk={clerk} setClerk={setClerk} />
                        );
                    }}
                </StoreContextConsumer>

                <div className="p-grid p-fluid">
                    <div className="p-col-12">
                        <StoreContextConsumer>
                            {({ currentStore, dateRange, setDateRange, clerk, terminal }) => {
                                return (
                                    <HourSalesLineChart store={currentStore} dates={dateRange} clerk={clerk} terminal={terminal} />
                                );
                            }}
                        </StoreContextConsumer>
                    </div>
                </div>

                <div className="p-grid p-fluid">
                    <div className="p-col-12">
                        <StoreContextConsumer>
                            {({ currentStore, dateRange, setDateRange, clerk, terminal }) => {
                                return (
                                    <HourSalesReport store={currentStore} dates={dateRange} clerk={clerk} terminal={terminal} />
                                );
                            }}
                        </StoreContextConsumer>
                    </div>
                </div>

            </>
        )
    }
}