import React from "react";
import apiClient from "../apiClient";
import { Chart } from "primereact/chart";
import { Card } from "primereact/card";
import moment from 'moment'

export class GrossNetChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false

    };
  }

  fetchData() {
    this.setState({ loading: true });
    const untilDate = moment().format('YYYY-M-DD')
    const clerkId = this.props.clerk.id;
    const terminalId = this.props.terminal.code;
    apiClient
      .get(
        `v1/report/fixedtotaliser/clerk=${clerkId}/terminal=${terminalId}/until_date=${untilDate}/sortorder=asc`
        //Report Gross and Net sales for seven days up until [until_date]
      )
      .then(res => {
        this.setState({ data: res.data.values, loading: false });
      })
      .catch(err => {
        this.setState({ data: [], loading: false });
        console.log('Error fetching GrossNetChart data: ', err)
      });
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if ((this.props.dates[1] === null) || (this.props.store === null)) return;
    if ((this.props.dates !== prevProps.dates) || (this.props.store !== prevProps.store) || (this.props.clerk !== prevProps.clerk) || (this.props.terminal !== prevProps.terminal)) {
      this.fetchData();
    }
  }

  render() {
    let days = [], gross = [], net = []
    this.state.data.forEach(item => {
      days.push(moment(item.cdate).format('D-MMM'))
      gross.push(item.gross[1])
      net.push(item.net[1])
    })
    const data = {
      labels: days,
      datasets: [
        {
          label: 'Gross Sales',
          backgroundColor: '#42A5F5',
          data: gross
        },
        {
          label: 'Net Sales',
          backgroundColor: '#9CCC65',
          data: net
        }
      ]
    };

    const options = {
      maintainAspectRatio: false,
      aspectRatio: 1.3,
      title: {
        display: true,
        text: "Gross and Net Sales Report for the last 7 days",
        fontSize: 16
      },
      legend: {
        position: "right",
        display: true
      },
    };

    return (
      <>
        <Card style={{ marginTop: "25px", height: '285px' }}>
          {this.state.loading ? (
            <div>Loading...</div>
          ) : this.state.data.length === 0 ? (
            <div>No data available, select different date range or store</div>
          ) : (
            <Chart type="bar" data={data} options={options} />
          )}
        </Card>
      </>
    );
  }
}

