import React from 'react';
import { Calendar } from "primereact/calendar";

export class DateRange extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dates: props.dates };
    this.calRef = React.createRef();
  }

  render() {
    return (
      <>
        <div style={{ height: '25px', color: "#999" }}>
          {this.state.dates[0] == null && <span style={{ color: 'black' }}>Select 'From' and 'To' date range</span>}
          {this.state.dates[0] != null && this.state.dates[1] == null && <span style={{ color: 'red' }}>Select 'To' date</span>}
        </div>

        <Calendar
          ref={this.calRef}
          dateFormat="dd-mm-yy"
          selectionMode="range"
          value={this.state.dates}
          showIcon={true}
          onChange={e => {
            let cal = this.calRef.current;
            this.setState((state, props) => {
              props.setDates(e.value);
              if (e.value[0] && e.value[1]) {
                cal.hideOverlay();
              }
              return { dates: e.value }
            })
          }}
          readOnlyInput={true}
          selectOtherMonths={true}
        />

      </>
    )

  }
}