import React from "react";
import apiClient from "../apiClient";
import { Card } from "primereact/card";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment'

export class MixAndMatchReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: false

        };
    }

    fetchData() {
        this.setState({ loading: true });
        const fromDate = moment(this.props.dates[0]).format('YYYY-MM-DD')
        const toDate = moment(this.props.dates[1]).format('YYYY-MM-DD')
        const clerkId = this.props.clerk.id;
        const terminalId = this.props.terminal.code;

        apiClient
            .get(
                `/v1/report/mixmatch/clerk=${clerkId}/terminal=${terminalId}/from_date=${fromDate}/to_date=${toDate}`
            )
            .then(res => {
                this.setState({ data: res.data.value, loading: false });
            })
            .catch(err => {
                this.setState({ data: [], loading: false });
                console.log('Error fetching Mix and Match data: ', err)
            });
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if ((this.props.dates[1] == null) || (this.props.store == null)) return;
        if ((this.props.dates !== prevProps.dates) || (this.props.store !== prevProps.store) || (this.props.clerk !== prevProps.clerk) || (this.props.terminal !== prevProps.terminal)) {
            this.fetchData();
        }
    }

    render() {

        return (
            <><div className="p-grid p-fluid">
                <div className="p-col-12">
                    <Card title="Mix & Match Report" style={{ marginTop: "25px" }}>
                        {this.state.loading ? (
                            <div>Loading...</div>
                        ) : this.state.data.length === 0 ? (
                            <div>No data available, select different date range or store</div>
                        ) : (
                                    <div>
                                        <div style={{ textAlign: 'right', marginBottom: '10px' }}>
                                            <span style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }} onClick={(e) => this.dataTable.exportCSV()}>Download CSV</span>
                                        </div>

                                        <DataTable ref={(el) => { this.dataTable = el; }} value={this.state.data} resizableColumns={true} columnResizeMode="fit" >
                                            <Column field="id" header="ID" bodyStyle={{ textAlign: "center" }} />
                                            <Column field="name" header="Name" />
                                            <Column field="qty" header="Quantity" bodyStyle={{ textAlign: "right" }} />
                                            <Column field="value" header="Amount" body={(rowData, column) => <span> {rowData.value.toFixed(2)}</span>} bodyStyle={{ textAlign: 'right' }} />

                                        </DataTable>

                                    </div>
                                )}
                    </Card>
                </div>
            </div>
            </>
        );
    }
}
