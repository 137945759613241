import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { Button } from "primereact/button";
import { InputText } from 'primereact/inputtext';
import './Login.css';
import { UserContextConsumer } from "../UserContext";

export class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitStatus: null
        }
    }

    formSubmit(values, loginFunction) {
        this.setState({ submitStatus: "Please wait..." });
        loginFunction(values.username, values.password).then(res => {
            if (res === true) {
                this.setState({ submitStatus: "" });
                this.props.history.push('/');
            } else {
                this.setState({ submitStatus: "* Login Failed *" });
            }
        })
    }

    render() {

        return (
            <UserContextConsumer>
                {({ isAuthenticated, loginWithPassword }) => (
                    <div className="login-bg">
                        <div className="p-grid p-align-center p-justify-center p-align-center">
                            <div className="p-col-12 p-sm-8 p-md-6">
                                <div className="login-card">
                                    <div className={'app-name'}><span style={{ color: 'red' }}>ECTouch </span>Office</div>
                                    <div><h4>Login</h4></div>
                                    <Form
                                        validate={(values) => {
                                            let errors = {};
                                            if (!values.username) {
                                                errors.username = 'Username required';
                                            }
                                            if (!values.password) {
                                                errors.password = 'Password required';
                                            }
                                            return errors;
                                        }}
                                        onSubmit={(values) => this.formSubmit(values, loginWithPassword)}>
                                        {({
                                            handleSubmit, form, submitting, pristine, submitError
                                        }) => (
                                            <form onSubmit={handleSubmit}>
                                                <div className='p-grid p-dir-col'>
                                                    <div className='p-col error-message'>{this.state.submitStatus}</div>
                                                    <div className='p-col'>
                                                        <Field name={'username'}>{
                                                            ({ input, meta }) => (
                                                                <div>
                                                                    <InputText placeholder='Username' name={input.name}
                                                                        value={input.value}
                                                                        onChange={input.onChange} />
                                                                    {meta.error && meta.touched &&
                                                                        <div className={'error-message'}>{meta.error}</div>}
                                                                </div>
                                                            )
                                                        }
                                                        </Field></div>
                                                    <div className='p-col'>
                                                        <Field name={'password'}>{
                                                            ({ input, meta }) => (
                                                                <div>
                                                                    <InputText placeholder='Password' type="password"
                                                                        name={input.name}
                                                                        value={input.value}
                                                                        onChange={input.onChange} />
                                                                    {meta.error && meta.touched &&
                                                                        <div className={'error-message'}>{meta.error}</div>}
                                                                </div>
                                                            )
                                                        }
                                                        </Field></div>
                                                    <div className='p-col'>
                                                        <Button label='Login' type="submit"
                                                            disabled={submitting || pristine}
                                                            className='p-button' />

                                                    </div>
                                                    <div className='p-col p-justify-center login-footer'>
                                                        {isAuthenticated && <Link to={'/'}>Home</Link>}
                                                    </div>
                                                </div>
                                            </form>
                                        )}
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </UserContextConsumer>
        );
    }
}

