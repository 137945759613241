import React, { Component } from "react";
import { Card } from 'primereact/card';
import apiClient from "../apiClient";

export class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false
    };
  }

  fetchData() {
    this.setState({ loading: true });
    apiClient
      .get(
        `v1/utils/get/software/info`
      )
      .then(res => {
        this.setState({ data: res.data, loading: false });
      })
      .catch(err => {
        this.setState({ data: [], loading: false });
        console.log('Error fetching About data: ', err)
      });
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    const item = this.state.data;
    return (
      <>
        <div className="p-grid">
          <div className="p-col-12">
            <div className='page-head'>About</div>
            <Card title="API Information " style={{ marginTop: "25px" }}>
              {this.state.loading ? (
                <div>Loading...</div>
              ) : this.state.data.length === 0 ? (
                <div>No data available</div>
              ) : (
                    <div className="p-grid ">
                      <div className="p-col-6" style={{ fontWeight: "bold", marginTop: '.1em' }}> Name :</div>
                      <div className="p-col-6">{item.name}</div>
                      <div className="p-col-6" style={{ fontWeight: "bold", marginTop: '.1em' }}>Version :</div>
                      <div className="p-col-6">{item.version}</div>
                      <div className="p-col-6" style={{ fontWeight: "bold", marginTop: '.1em' }}>Description :</div>
                      <div className="p-col-6">{item.description}</div>
                      <div className="p-col-6" style={{ fontWeight: "bold", marginTop: '.1em' }}>Copyright :</div>
                      <div className="p-col-6">{item.copyright}</div>
                      <div className="p-col-6" style={{ fontWeight: "bold", marginTop: '.1em' }}>Author Name :</div>
                      <div className="p-col-6">{item.author.name}</div>
                      <div className="p-col-6" style={{ fontWeight: "bold", marginTop: '.1em' }}>Author Company :</div>
                      <div className="p-col-6">{item.author.company}</div>
                    </div>
                  )}
            </Card>
          </div>
        </div>
      </>
    );
  }
}
