import React from 'react'
import apiClient from "./apiClient";

const UserContext = React.createContext();

class UserContextProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            user: null,
        };
        this.loginWithPassword = this.loginWithPassword.bind(this);
        this.logout = this.logout.bind(this);
    }

    loginWithPassword(username, password) {
        return apiClient.get(`v1/users/login/userby/email=${username}/password=${password}`)
            .then(res => {
                if (res.data.resultcode === 0) {
                    this.setState({ isAuthenticated: true, user: res.data.result });
                    return true
                } else {
                    this.logout();
                    return res.data.message;
                }
            })
            .catch(err => {
                this.logout();
                return err.message;
            });
    }

    logout() {
        localStorage.removeItem('access');
        this.setState({ isAuthenticated: false, user: null });
        if (this.state.isAuthenticated) {
            apiClient.get(`v1/users/logout/userby/userid=${this.state.user.id}`)
        }
    }

    render() {
        return (
            <UserContext.Provider
                value={{
                    isAuthenticated: this.state.isAuthenticated,
                    loginWithPassword: this.loginWithPassword,
                    logout: this.logout,
                    user: this.state.user,
                }}>
                {this.props.children}
            </UserContext.Provider>
        )
    }
}

const UserContextConsumer = UserContext.Consumer;

export { UserContext, UserContextProvider, UserContextConsumer }