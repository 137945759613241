import React from "react";
import apiClient from "../apiClient";
import { Card } from "primereact/card";
import moment from 'moment'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export class Transaction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            visible: false,
            loading: false,
        };
    }

    fetchData() {
        this.setState({ loading: true });
        const fromDate = moment(this.props.dates[0]).format('YYYY-MM-DD')
        const toDate = moment(this.props.dates[1]).format('YYYY-MM-DD')
        const clerkId = this.props.clerk.id;
        const terminalId = this.props.terminal.code;
        apiClient
            .get(
                // transaction api
                `v1/get/orders/clerk=${clerkId}/terminal=${terminalId}/customer=0/date_from=${fromDate}/date_to=${toDate}/limit_rec=0/limit_row=5/sortorder=desc`,
            )
            .then(res => {
                this.setState({ data: res.data.values, loading: false });
            })
            .catch(err => {
                this.setState({ data: [], loading: false });
                console.log('Error in fetchData: ', err)
            })
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if ((this.props.dates[1] == null) || (this.props.store == null)) return;
        if ((this.props.dates !== prevProps.dates) || (this.props.store !== prevProps.store) || (this.props.clerk !== prevProps.clerk)) {
            this.fetchData();
        }
    }

    dateTemplate(rowData, column) {
        return <span>{rowData.cdate}</span>;
        // return <span>{moment(rowData.date).format('DD-MM-YYYY')}</span>;
    }

    timeTemplate(rowData, column) {
        return <span>{moment(rowData.time, 'HH:mm:ss').format('HH:mm')}</span>;
    }
    amountTemplate(rowData, column) {
        return <span>{Number(rowData.total).toFixed(2)}</span>;
    }

    statusTemplate(rowData, column) {
        const status = rowData.status === 0 ? 'Hold' : rowData.status === 1 ? 'Paid' : 'Unassigned'
        return <span>{status}</span>
    }

    render() {

        return (

            <Card title=" Recent Transactions " style={{ marginTop: "25px" }}>
                {this.state.loading ? (
                    <div>Loading...</div>
                ) : this.state.data.length === 0 ? (
                    <div>No data available, select different date range or store</div>
                ) : (
                            <DataTable value={this.state.data} responsive={true} style={{ textAlign: 'center' }}>
                                <Column header="Date" body={this.dateTemplate} />
                                <Column header="Time" body={this.timeTemplate} />
                                <Column field="consno" header="Cons No." />
                                <Column field="tableno" header="Tbl/Tkt No." />
                                <Column field="clerk_id" header="Clerk ID" />
                                <Column field="customer_id" header="Customer" />
                                <Column field="total" header="Total" style={{ textAlign: 'right' }} body={this.amountTemplate} />
                                <Column field="status" header="Status" body={this.statusTemplate} />
                            </DataTable>
                        )}
            </Card >
        );
    }
}