import React from "react";
import apiClient from "../apiClient";
import { Card } from "primereact/card";
import moment from 'moment'

export class FullFixedReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: false
        };
    }

    fetchData() {
        this.setState({ loading: true });
        this.setState({ loading: true });
        const fromDate = moment(this.props.dates[0]).format('YYYY-MM-DD')
        const toDate = moment(this.props.dates[1]).format('YYYY-MM-DD')
        const clerkId = this.props.clerk.id;
        const terminalId = this.props.terminal.code;
        //Full Fixed totalizer API
        apiClient
            .get(
                `v1/report/fixedtotaliser/descriptive/rec=3/clerk=${clerkId}/terminal=${terminalId}/from_date=${fromDate}/to_date=${toDate}`
            )
            .then(res => {
                this.setState({ data: res.data.values, loading: false });
            })
            .catch(err => {
                this.setState({ data: [], loading: false });
                console.log('Error fetching Financial data: ', err)
            });
    }

    componentDidMount() {
        this.fetchData();

    }

    componentDidUpdate(prevProps) {
        if ((this.props.dates[1] == null) || (this.props.store == null)) return;
        if ((this.props.dates !== prevProps.dates) || (this.props.store !== prevProps.store) || (this.props.clerk !== prevProps.clerk) || (this.props.terminal !== prevProps.terminal)) {
            this.fetchData();
        }
    }

    render() {
        const items = Object.values(this.state.data);
        return (
            <Card title="Financial Report " style={{ marginTop: "25px" }}>
                {this.state.loading ? (
                    <div>Loading...</div>
                ) : this.state.data.length === 0 ? (
                    <div>No data available, select different date range or store</div>
                ) : (
                            <div className="p-datatable p-component">
                                <div className="p-datatable-wrapper">
                                    <table role="grid" value={this.state.data} >
                                        <thead className="p-datatable-thead">
                                            <tr>
                                                <th>Description</th>
                                                <th>Quantity</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody className="p-datatable-tbody">
                                            {
                                                items.map((item, index) => (<tr key={index}>
                                                    <td>{item.description}</td>
                                                    <td align="right">{item.data[0]}</td>
                                                    <td align="right">{item.data[1].toFixed(2)} </td>
                                                </tr>))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
            </Card>
        );
    }
}
