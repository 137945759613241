import React from "react";
import apiClient from "../apiClient";
import { Chart } from "primereact/chart";
import { Card } from "primereact/card";
import moment from 'moment';

export class DeptSalesChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false
    };
  }

  fetchData() {
    this.setState({ loading: true });
    const fromDate = moment(this.props.dates[0]).format('YYYY-MM-DD')
    const toDate = moment(this.props.dates[1]).format('YYYY-MM-DD')
    const clerkId = this.props.clerk.id;
    const terminalId = this.props.terminal.code;
    apiClient
      .get(
        `v1/report/department/clerk=${clerkId}/terminal=${terminalId}/from_date=${fromDate}/to_date=${toDate}`
        //Department sales report
      )
      .then(res => {
        this.setState({ data: res.data.values, loading: false });
      })
      .catch(err => {
        this.setState({ data: [], loading: false });
        console.log('Error fetching Derpartment data: ', err)
      });
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if ((this.props.dates[1] === null) || (this.props.store === null)) return;
    if ((this.props.dates !== prevProps.dates) || (this.props.store !== prevProps.store) || (this.props.clerk !== prevProps.clerk) || (this.props.terminal !== prevProps.terminal)) {
      this.fetchData();
    }
  }

  render() {
    const data = {
      labels: this.state.data.map(i => i.name),
      datasets: [
        {
          label: "Department",
          backgroundColor: [
            "#ace600",
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#d580ff",
            '#ff9933',
            '#66ff66',
            '#ffb3d9',
            '#33ff77',
            '#cccc00',
            '#e60000',
            '#999966',
            '#3939ac'],
          data: this.state.data.map(i => i.QtySum)
        }
      ]
    };

    const option = {
      title: {
        display: true,
        text: 'Sales Quantity by Department',
        fontSize: 16
      },
      legend: {
        position: 'right'
      }
    };

    return (
      <>
        <Card style={{ marginTop: "25px" }}>
          {this.state.loading ? (
            <div>Loading...</div>
          ) : this.state.data.length === 0 ? (
            <div>No data available, select different date range or store</div>
          ) : (
                <Chart type="pie" data={data} options={option} />
              )}
        </Card>
      </>
    );
  }
}
