import React from "react";
import apiClient from "../apiClient";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import moment from 'moment'

export class OrderLineSalesReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orderLineData: [],
            customerData: [],
            loading: false,
        };
    }

    fetchData() {
        this.setState({ loading: true });
        const clerkId = this.props.clerk.id;
        const terminalId = this.props.terminal.code;
        apiClient.get(`v1/get/orderlines/id=${this.props.orderId}`)
            .then(res => {
                this.setState({ orderLineData: res.data.values });
                return apiClient.get(`v1/get/orders/clerk=${clerkId}/terminal=${terminalId}/from_id=${this.props.orderId}/to_id=${this.props.orderId}`)
            })
            .then(res => {
                this.setState({ customerData: res.data.values, loading: false })
            })
            .catch(err => {
                this.setState({ orderLineData: [], customerData: [], loading: false })
                console.log('Error fetching api data', err)
            })
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if ((this.props.orderId !== prevProps.orderId)) {
            this.fetchData();
        }
    }

    valueTemplate(rowData, column) {
        return <span>{rowData.value.toFixed(2)}</span>;
    }

    render() {
        const rowdata = this.state.customerData[0]
        let headergroup = <ColumnGroup>
            <Row>
                <Column header="Item name (Description)" />
                <Column header="Qty" />
                <Column header="Tax No." />
                <Column header="Value" />
            </Row>
        </ColumnGroup>

        return (
            <div>
                {this.state.loading ? (
                    <div>Loading...</div>
                ) : this.state.customerData.length === 0 ? (
                    <div>No data available, select different date range or store</div>
                ) : (
                            <>
                                <div className="p-grid p-fluid ">
                                    <div className="p-col-12 ">
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-4 p-lg-4 ">
                                                <div className="p-grid">
                                                    <div className="p-col-6 p-lg-4 gridcolor">
                                                        <div className='p-col'> <label>Date </label> </div>
                                                        <div className='p-col'> <label>Time </label> </div>
                                                        <div className='p-col'> <label>Mode </label> </div>
                                                        <div className='p-col'> <label>Cons No.</label> </div>
                                                        <div className='p-col'> <label>Tbl/Tkt No. </label> </div>
                                                        <div className='p-col'> <label>Clerk ID </label> </div>
                                                    </div>
                                                    <div className="p-col-6 p-lg-8 ">
                                                        <div className='p-col'> {rowdata.cdate}</div>
                                                        <div className='p-col'> {moment(rowdata.time, 'HH:mm:ss').format('HH:mm')}</div>
                                                        <div className='p-col'> {rowdata.mode}</div>
                                                        <div className='p-col'> {rowdata.consno}</div>
                                                        <div className='p-col'> {rowdata.tableno} </div>
                                                        <div className='p-col'> {rowdata.clerk_id} </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="p-col-12 p-md-4 p-lg-4 ">
                                                <div className="p-grid">
                                                    <div className="p-col-6 p-lg-4 gridcolor">
                                                        <div className='p-col'> <label>Status </label> </div>
                                                        <div className='p-col'> <label>Check Text </label> </div>
                                                        <div className='p-col'> <label>Location </label> </div>
                                                        <div className='p-col'> <label>Customer No </label> </div>
                                                        <div className='p-col'> <label>Key code</label> </div>
                                                        <div className='p-col'> <label>Telephone</label> </div>
                                                    </div>
                                                    <div className="p-col-6 p-lg-8 ">
                                                        <div className='p-col'> {rowdata.status} </div>
                                                        <div className='p-col'> {rowdata.checktext} </div>
                                                        <div className='p-col'> {rowdata.location} </div>
                                                        <div className='p-col'> {rowdata.customer_id}</div>
                                                        <div className='p-col'> {rowdata.keycode}</div>
                                                        <div className='p-col'> {rowdata.telephone}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="p-col-12 p-md-4 p-lg-4 ">
                                                <div className="p-grid">
                                                    <div className="p-col-6 p-lg-4 gridcolor">
                                                        <div className='p-col'> <label>First name</label> </div>
                                                        <div className='p-col'> <label>Surname</label> </div>
                                                        <div className='p-col'> <label>Address 1</label> </div>
                                                        <div className='p-col'> <label>Address 2</label> </div>
                                                        <div className='p-col'> <label>Address 3</label> </div>
                                                        <div className='p-col'> <label>Postcode</label> </div>
                                                    </div>
                                                    <div className="p-col-6 p-lg-8 ">
                                                        <div className='p-col'> {rowdata.fname}</div>
                                                        <div className='p-col'> {rowdata.sname}</div>
                                                        <div className='p-col'> {rowdata.address1}</div>
                                                        <div className='p-col'> {rowdata.address2}</div>
                                                        <div className='p-col'> {rowdata.address3}</div>
                                                        <div className='p-col'> {rowdata.postcode}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <DataTable style={{ marginTop: "10px" }} value={this.state.orderLineData} scrollable={true} scrollHeight='200px' headerColumnGroup={headergroup} reorderableColumns={true}>
                                    <Column field="itemname" bodyStyle={{ textAlign: 'left' }} />
                                    <Column field="qty" bodyStyle={{ textAlign: 'center' }} />
                                    <Column field="tax_id" bodyStyle={{ textAlign: 'center' }} />
                                    <Column field="value" bodyStyle={{ textAlign: 'center' }} body={this.valueTemplate} />
                                </DataTable>
                            </>
                        )}
            </div>
        );
    }
}