import React, { Component } from "react";
import { CustomerReport } from "../componets/CustomerReport";
import { StoreContextConsumer } from "../StoreContext";
import { FilterComponent } from "../componets/FilterComponent";

export class CustomerReportPage extends Component {
  render() {
    return (
      <div>
        <div className="page-head">Customer Report </div>
        <div className="p-grid p-fluid">
          <div className="p-col-12 ">
            <StoreContextConsumer>
              {({ terminal, setTerminal, clerk, setClerk }) => {
                return (
                  <FilterComponent terminal={terminal} setTerminal={setTerminal} clerk={clerk} setClerk={setClerk} />
                );
              }}
            </StoreContextConsumer>
          </div>
          <div className="p-col-12 ">
            <StoreContextConsumer>
              {({ currentStore, dateRange, clerk, terminal, totalCount }) => {
                return totalCount.length !== 0 && (
                  currentStore && <CustomerReport store={currentStore} dates={dateRange} clerk={clerk} terminal={terminal} totalCount={totalCount} />
                );
              }}
            </StoreContextConsumer>
          </div>
        </div>

      </div>
    )
  }
}
