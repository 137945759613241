import React from "react";
import { GroupSalesReport } from "../componets/GroupSalesReport";
import { StoreContextConsumer } from "../StoreContext";
import { FilterComponent } from "../componets/FilterComponent";
import { GroupSalesAmountChart } from "../componets/GroupSalesAmountChart";
import { GroupSalesQtyChart } from "../componets/GroupSalesQtyChart";

export class GroupSalesPage extends React.Component {
    render() {
        return (
            <>
                <div className="page-head"> Group Report</div>

                <StoreContextConsumer>
                    {({ terminal, setTerminal, clerk, setClerk }) => {
                        return (
                            <FilterComponent terminal={terminal} setTerminal={setTerminal} clerk={clerk} setClerk={setClerk} />
                        );
                    }}
                </StoreContextConsumer>

                <div className="p-grid p-fluid">
                    <div className="p-col-12 p-md-6">
                        <StoreContextConsumer>
                            {({ currentStore, dateRange, clerk, terminal, setDateRange }) => {
                                return (
                                    <GroupSalesQtyChart terminal={terminal} clerk={clerk} store={currentStore} dates={dateRange} />
                                );
                            }}
                        </StoreContextConsumer>
                    </div>

                    <div className="p-col-12 p-md-6">
                        <StoreContextConsumer>
                            {({ currentStore, dateRange, clerk, terminal, setDateRange }) => {
                                return (
                                    <GroupSalesAmountChart terminal={terminal} clerk={clerk} store={currentStore} dates={dateRange} />
                                );
                            }}
                        </StoreContextConsumer>
                    </div>

                </div>

                <div className="p-grid p-fluid">
                    <div className="p-col-12">
                        <StoreContextConsumer>
                            {({ currentStore, dateRange, clerk, terminal, setDateRange }) => {
                                return (
                                    <GroupSalesReport terminal={terminal} clerk={clerk} store={currentStore} dates={dateRange} />
                                );
                            }}
                        </StoreContextConsumer>
                    </div>
                </div>
            </>
        )
    }
}