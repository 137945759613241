import React, { Component } from "react";
import { Card } from 'primereact/card'
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import apiClient from "../apiClient";
import { Column } from 'primereact/column';


export class CustomerTransactionReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      visible: false,
      loading: false,
      searchType: 'keycode',
      searchValue: ''
    };
    this.fetchData = this.fetchData.bind(this)
    this.onRowClick = this.onRowClick.bind(this);
  }

onRowClick(e) {
  this.props.history.push({pathname: `/transactions/${e.data.id}`, state: {custName: `${e.data.fname} ${e.data.sname}`}})
}

rowHighLight(rowData) {
  return { 'p-highlight': (rowData.id === this.state.orderId) };
}


  fetchData() {
    this.setState({ loading: true });
    let url = this.state.searchType == 'keycode' ? `v1/get/customers/keycode=${this.state.searchValue}` : `v1/get/customers/postcode=${this.state.searchValue}`
    apiClient
      .get(
        // customer search api
        url
      )
      .then(res => {
        this.setState({ data: res.data.values, loading: false });
      })
      .catch(err => {
        this.setState({ data: [], loading: false });
        console.log('Error in fetchData: ', err)
      })
  }

  render() {
    return (
      <div>
        <div className="page-head">Customer Transactions Report </div>
        <div className="p-grid">
          <div className="p-col-12">
            <div className="p-grid">
              <div className='p-col-2'>
                Search by
               </div>
              <div className='p-col-2'>
                <Dropdown value={this.state.searchType}
                  options={[{ label: 'Key code', value: 'keycode' }, { label: 'Post code', value: 'postcode' }]}
                  onChange={(e) => { this.setState({ searchType: e.value }) }} />
              </div>
              <div className='p-col-3'>
                <InputText value={this.state.searchValue} onChange={(e) => this.setState({ searchValue: e.target.value })} />
              </div>
              <div className='p-col-2'>
                <Button label="Search" onClick={this.fetchData} />
              </div>
            </div>
          </div>
          <div className="p-col-12 ">
            <Card title="Search Result" style={{ marginTop: "25px" }}>
              {this.state.loading ? (
                <div>Loading...</div>
              ) : this.state.data.length === 0 ? (
                <div>No data available, search with different input</div>
              ) : (
                    <DataTable value={this.state.data} responsive={true} style={{ textAlign: 'center' }} onRowClick={this.onRowClick}>
                      <Column field="id" header="ID" />
                      <Column header="Name" body={(rowData) => `${rowData.fname} ${rowData.sname}`} />
                      <Column field="telephone" header="Telephone" />
                      <Column field="address1" header="Address" />
                      <Column field="postcode" header="Postcode" />
                    </DataTable>
                  )}
            </Card >
          </div>
        </div>

      </div >
    )
  }
}
