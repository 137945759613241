import React from "react";
import apiClient from "../apiClient";
import { Chart } from "primereact/chart";
import { Card } from "primereact/card";
import moment from 'moment'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { StoreContext } from "../StoreContext";

export class PluChart extends React.Component {
  static contextType = StoreContext;
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      totalRecords: [],
    };
  }

  fetchData() {
    this.setState({ loading: true });
    const fromDate = moment(this.props.dates[0]).format('YYYY-MM-DD')
    const toDate = moment(this.props.dates[1]).format('YYYY-MM-DD')
    const clerkId = this.props.clerk.id;
    const terminalId = this.props.terminal.code;
    const toId = this.props.totalCount[4].memory;
    apiClient
      .get(
        `/v1/report/plu/clerk=${clerkId}/terminal=${terminalId}/from_id=0/to_id=${toId}/from_date=${fromDate}/to_date=${toDate}/sortby=qty/limit_row=200`
        //To get best sellers report (PLU sorted by Qty) 
      )
      .then(res => {
        this.setState({ data: res.data.values, loading: false });

      })
      .catch(err => {
        this.setState({ data: [], loading: false });
        console.log('Error on fetchData: ', err)
      })
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if ((this.props.dates[1] === null) || (this.props.store === null)) return;
    if ((this.props.dates !== prevProps.dates) || (this.props.store !== prevProps.store) || (this.props.clerk !== prevProps.clerk) || (this.props.terminal !== prevProps.terminal)) {
      this.fetchData();
    }
  }

  render() {
    const name = this.state.data.map(i => i.name);
    const qty = this.state.data.map(i => i.qtysum);
    const data = {

      labels: name,
      datasets: [
        {
          data: qty,
          label: 'Gross Qty',
          fill: false,
          backgroundColor: '#9CCC65',
          borderColor: '#9CCC65'
        },
      ]
    };

    const options = {
      responsive: true,
      title: {
        display: true,
        text: "Best Sellers",
        fontSize: 16,
      },
      legend: {
        position: "right",
        display: false
      },
      scales: {
        xAxes: [{
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Gross'
          }
        }]
      }
    };

    return (
      <>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <Card style={{ marginTop: "25px" }}>
              {this.state.loading ? (
                <div>Loading...</div>
              ) : this.state.data.length === 0 ? (
                <div>No data available, select different date range or store</div>
              ) : (
                <Chart type="horizontalBar" data={data} options={options} style={{ width: '85%', marginLeft: '50px' }} />
              )}
            </Card>
          </div>
        </div>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <Card title="Best Sellers" style={{ marginTop: "25px" }}>
              <DataTable ref={(el) => { this.dataTable = el; }} value={this.state.data}
                totalRecords={this.state.totalRecords} lazy={false} first={this.state.first}
                onPage={this.onPage} loading={this.state.loading}
                scrollable={true} paginator={false} rows={this.state.rows}
                style={{ width: '100%' }}
                resizableColumns={true} columnResizeMode="fit" paginatorPosition='both'>
                <Column bodyStyle={{ textAlign: "center" }} field="id" header="ID" />
                <Column field="name" header="Name" />
                <Column bodyStyle={{ textAlign: "right" }} field="qtysum" sortable={true} header="Quantity" />
                <Column bodyStyle={{ textAlign: "right" }} field="valuesum" header="Amount" body={(rowData, column) => <span>{rowData.valuesum.toFixed(2)}</span>} />
              </DataTable>
            </Card>
          </div>
        </div>
      </>
    );
  }
}