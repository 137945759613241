import React from "react";
import apiClient from "../apiClient";
import { Chart } from "primereact/chart";
import { Card } from "primereact/card";
import moment from 'moment'

export class HourSalesLineChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: false

        };
    }

    fetchData() {
        this.setState({ loading: true });
        const fromDate = moment(this.props.dates[0]).format('YYYY-MM-DD')
        const toDate = moment(this.props.dates[1]).format('YYYY-MM-DD')
        const id_clerk = this.props.clerk.id;
        const id_terminal = this.props.terminal.code;
        // HourSalesReportApi
        apiClient
            .get(
                `/v1/report/hourlysales/clerk=${id_clerk}/terminal=${id_terminal}/from_date=${fromDate}/to_date=${toDate}`
            )
            .then(res => {
                this.setState({ data: res.data.values, loading: false });
            })
            .catch(err => {
                this.setState({ data: [], loading: false });
                console.log('Error fetching HourlySales data: ', err)
            });
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if ((this.props.dates[1] === null) || (this.props.store === null)) return;
        if ((this.props.dates !== prevProps.dates) || (this.props.store !== prevProps.store) || (this.props.clerk !== prevProps.clerk) || (this.props.terminal !== prevProps.terminal)) {
            this.fetchData();
        }
    }
    render() {

        const qty = {
            labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
            datasets: [
                {
                    data: this.state.data.map(item => item.gross[1].toFixed(2)),
                    label: 'Gross Amount',
                    fill: false,
                    backgroundColor: '#42A5F5 ',
                    borderColor: '#42A5F5'
                },
            ]
        };

        const amount = {
            labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
            datasets: [
                {
                    data: this.state.data.map(item => item.net[1].toFixed(2)),
                    label: 'Net Amount',
                    fill: false,
                    backgroundColor: '#42A5F5',
                    borderColor: '#42A5F5'
                }
            ]
        };

        const qtyOptions = {
            title: {
                display: true,
                text: "Hourly Sales (Gross)",
                fontSize: 16
            },
            legend: {
                position: "right",
                display: false
            },
            scales: {
                xAxes: [{
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: 'Hour'
                    }
                }],
                yAxes: [{
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: 'Gross Amount'
                    }
                }]
            }
        };

        const amountOptions = {
            title: {
                display: true,
                text: "Hourly Sales (Net)",
                fontSize: 16
            },
            legend: {
                position: "right",
                display: false
            },
            scales: {
                xAxes: [{
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: 'Hour'
                    }
                }],
                yAxes: [{
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: 'Net Amount'
                    }
                }]
            }
        };

        return (
            <>
                <div className="p-grid p-fluid">
                    <div className="p-col-12 p-lg-6">
                        <Card style={{ marginTop: "25px" }}>
                            {this.state.loading ? (
                                <div>Loading...</div>
                            ) : this.state.data.length === 0 ? (
                                <div>No data available, select different date range or store</div>
                            ) : (
                                        <Chart type="bar" data={qty} options={qtyOptions} />
                                    )}
                        </Card>
                    </div>

                    <div className="p-col-12 p-lg-6">
                        <Card style={{ marginTop: "25px" }}>
                            {this.state.loading ? (
                                <div>Loading...</div>
                            ) : this.state.data.length === 0 ? (
                                <div>No data available, select different date range or store</div>
                            ) : (
                                        <Chart type="bar" data={amount} options={amountOptions} />
                                    )}
                        </Card>
                    </div>
                </div>
            </>
        );
    }
}
