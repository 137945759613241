import React from "react";
import apiClient from "../apiClient";
import { Card } from "primereact/card";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment'
export class PluSalesReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            first: 0,
            totalRecords: 0,
            loading: true,
            submitted: true,
            rows: 25,
            data: []
        };
        this.onPage = this.onPage.bind(this)
    }

    onPage(event) {
        this.setState({ first: event.first, rows: event.rows, })

    }

    componentDidMount() {
        this.setState(() => ({ totalRecords: this.props.totalCount[4].memory }),
            () => this.fetchData(this.state.first, this.state.first + this.state.rows))
    }

    fetchData(from, to) {
        this.setState({ loading: true });
        const fromDate = moment(this.props.dates[0]).format('YYYY-MM-DD')
        const toDate = moment(this.props.dates[1]).format('YYYY-MM-DD')
        const clerkId = this.props.clerk.id;
        const terminalId = this.props.terminal.code;
        apiClient
            .get(
                //Plu SalesReport api
                `v1/report/plu/clerk=${clerkId}/terminal=${terminalId}/from_id=1/to_id=${this.state.totalRecords}/from_date=${fromDate}/to_date=${toDate}/sortby=PLU-id/limit_offset=${from}/limit_row=${to}`
            )
            .then(res => {
                this.setState({ data: res.data.values, loading: false });
            })
            .catch(err => {
                this.setState({ data: [], loading: false });
                console.log('Error fetching PLU data: ', err)
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.props.dates[1] === null) || (this.props.store === null)) return;
        if ((this.props.dates !== prevProps.dates) || (this.props.clerk !== prevProps.clerk) || (this.props.terminal !== prevProps.terminal)) {
            this.setState({ first: 0 })
            this.fetchData(0, this.state.rows)
        }
        if (this.props.store !== prevProps.store) {
            this.setState({ first: 0, totalRecords: this.props.totalCount[4].memory })
            this.fetchData(0, this.state.rows)
        }
        if ((this.state.first !== prevState.first) || (this.state.rows !== prevState.rows)) {
            this.fetchData(this.state.first, this.state.first + this.state.rows)
        }
    }

    render() {
        return (
            <>
                <Card title="PLU Report" style={{ marginTop: "25px" }}>
                    {this.state.loading ? (
                        <div>Loading...</div>
                    ) :
                        (
                            <div>
                                <div> Showing records  from <b>{this.state.first + 1}</b> to <b>{this.state.first + this.state.rows}</b> </div>
                                <div style={{ textAlign: 'right', marginBottom: '10px' }}>
                                    <span style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }} onClick={(e) => this.dataTable.exportCSV()}>Download CSV</span>
                                </div>

                                <DataTable ref={(el) => { this.dataTable = el; }} value={this.state.data}
                                    totalRecords={this.state.totalRecords} lazy={true} first={this.state.first}
                                    onPage={this.onPage} loading={this.state.loading}
                                    scrollable={true} paginator={true} rows={this.state.rows}
                                    rowsPerPageOptions={[25, 50, 100, 200]} style={{ width: '100%' }}
                                    resizableColumns={true} columnResizeMode="fit" paginatorPosition='both'>
                                    <Column bodyStyle={{ textAlign: "center" }} field="id" header="ID" />
                                    <Column field="name" header="Name" />
                                    <Column bodyStyle={{ textAlign: "right" }} field="qtysum" header="Quantity" />
                                    <Column bodyStyle={{ textAlign: "right" }} field="valuesum" header="Amount" body={(rowData, column) => <span>{rowData.valuesum.toFixed(2)}</span>} />
                                </DataTable>

                            </div>
                        )}
                </Card>
            </>
        );
    }
}
