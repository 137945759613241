import React from "react";
import apiClient from "../apiClient";
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment'

export class DepartmentSalesReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: false
        };
    }
    fetchData() {
        this.setState({ loading: true });
        const fromDate = moment(this.props.dates[0]).format('YYYY-MM-DD')
        const toDate = moment(this.props.dates[1]).format('YYYY-MM-DD')
        const clerkId = this.props.clerk.id;
        const terminalId = this.props.terminal.code;
        apiClient
            .get(
                `v1/report/department/clerk=${clerkId}/terminal=${terminalId}/from_date=${fromDate}/to_date=${toDate}`
                // Department sales report API
            )
            .then(res => {
                this.setState({ data: res.data.values, loading: false });
            })
            .catch(err => {
                this.setState({ data: [], loading: false });
                console.log('Error fetching Derpartment data: ', err)
            });
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if ((this.props.dates[1] === null) || (this.props.store === null)) return;
        if ((this.props.dates !== prevProps.dates) || (this.props.store !== prevProps.store) || (this.props.clerk !== prevProps.clerk) || (this.props.terminal !== prevProps.terminal)) {
            this.fetchData();
        }
    }

    valueTemplate(rowData, column) {
        return <span>{rowData.ValueSum.toFixed(2)}</span>;
    }

    render() {
        return (
            <Card title="Department Report" style={{ marginTop: '25px' }}>
                {this.state.loading ? (
                    <div>Loading...</div>
                ) : this.state.data.length === 0 ? (
                    <div>No data available, select different date range or store</div>
                ) : (
                            <div>

                                <div style={{ textAlign: 'right', marginBottom: '10px' }}>
                                    <span style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }} onClick={(e) => this.dataTable.exportCSV()}>Download CSV</span>
                                </div>

                                <DataTable ref={(el) => { this.dataTable = el; }} value={this.state.data} resizableColumns={true} columnResizeMode="fit">
                                    <Column field="id" header="ID" style={{ width: '10%' }} bodyStyle={{ textAlign: "center" }} />
                                    <Column field="name" header="Name" />
                                    <Column field="QtySum" header="Quantity " bodyStyle={{ textAlign: "right" }} />
                                    <Column field="ValueSum" header="Amount (£)" body={this.valueTemplate} bodyStyle={{ textAlign: "right" }} />
                                </DataTable>

                            </div>
                        )}
            </Card>
        );
    }
}



