import React, {Component} from 'react'


export default class AutoLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {loaded: false}
    }

    async componentDidMount() {
        if (this.props.authStatus === false) {
            let refreshToken = localStorage.getItem('refresh');
            if (refreshToken) {
                await this.props.loginHandler(refreshToken);
            }
        }
        this.setState({loaded: true})
    }

    render() {
        return this.state.loaded ? this.props.children : <div> Loading... </div>
    }

}