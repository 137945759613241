import React from "react";
import { Dropdown } from 'primereact/dropdown';
import { DateSelector } from "./DateSelector";
import { StoreContextConsumer } from "../StoreContext";

export class FilterComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            terminal: props.terminal,
            clerk: props.clerk,
        };
    }

    render() {
        const terminals = [
            { name: 'All Terminal', code: 0 },
            { name: 'Terminal 1', code: 1 },
            { name: 'Terminal 2', code: 2 },
            { name: 'Terminal 3', code: 3 },
            { name: 'Terminal 4', code: 4 },
            { name: 'Terminal 5', code: 5 },
            { name: 'Terminal 6', code: 6 },
            { name: 'Terminal 7', code: 7 },
            { name: 'Terminal 8', code: 8 },
            { name: 'Terminal 9', code: 9 }
        ];

        return (
            <>
                <div className="p-grid p-fluid">
                    <div className="p-col-12 p-md-12 p-lg-12">
                        <div className="p-grid  p-fluid">
                            <div className="p-col-12 p-lg-12 ">
                                <StoreContextConsumer>
                                    {({ currentStore, dateRange, setDateRange, filterDate, setFilterDate }) => {
                                        return <DateSelector dates={dateRange}
                                            setDates={setDateRange} filterDate={filterDate} setFilterDate={setFilterDate} />;
                                    }}
                                </StoreContextConsumer>
                            </div>

                            <div className="p-col-12  p-lg-6">
                                <div style={{ height: '25px', color: "#999" }}>
                                    <span style={{ color: 'black' }}>Terminal</span>
                                </div>

                                <StoreContextConsumer>
                                    {({ terminal, setTerminal }) => {
                                        return <Dropdown name="Terminal" value={this.state.terminal}
                                            options={terminals}
                                            onChange={e => {
                                                this.setState({ terminal: e.value })
                                                this.props.setTerminal(e.value)
                                            }}
                                            optionLabel="name" />
                                    }}
                                </StoreContextConsumer>
                            </div>

                            <div className="p-col-12 p-lg-6">
                                <div style={{ height: '25px', color: "#999" }}>
                                    <span style={{ color: 'black' }}>Clerk</span>
                                </div>

                                <StoreContextConsumer>
                                    {({ currentStore, dateRange, setDateRange, clerkOptions }) => {
                                        return <Dropdown dataKey="id" name="Clerk" value={this.state.clerk} options={clerkOptions}
                                            onChange={e => {
                                                this.setState({ clerk: e.value })
                                                this.props.setClerk(e.value)
                                            }} optionLabel="name" />
                                    }}
                                </StoreContextConsumer>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}