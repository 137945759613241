import React from "react";
import { StoreContextConsumer } from "../StoreContext";
import { MixAndMatchReport } from "../componets/MixAndMatchReport";
import { FilterComponent } from "../componets/FilterComponent";

export class MixAndMatchPage extends React.Component {
    render() {
        return (
            <>
                <div className="page-head">Mix & Match Report</div>
                <div className="p-grid p-fluid">
                    <div className="p-col-12 ">
                        <StoreContextConsumer>
                            {({ terminal, setTerminal, clerk, setClerk }) => {
                                return (
                                    <FilterComponent terminal={terminal} setTerminal={setTerminal} clerk={clerk} setClerk={setClerk} />
                                );
                            }}
                        </StoreContextConsumer>
                    </div>

                    <div className="p-col-12">
                        <StoreContextConsumer>
                            {({ currentStore, dateRange, setDateRange, clerk, terminal }) => {
                                return (
                                    <MixAndMatchReport store={currentStore} dates={dateRange} clerk={clerk} terminal={terminal} />
                                );
                            }}
                        </StoreContextConsumer>
                    </div>
                </div>

            </>
        );
    }
}
