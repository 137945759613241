import React, { Component } from "react";
import { FilterComponent } from "../componets/FilterComponent";
import { StoreContextConsumer } from '../StoreContext';
import { DepartmentSalesReport } from "../componets/DepartmentSalesReport";
import { DeptSalesChartAmount } from "../componets/DeptChartAmount";
import { DeptSalesChart } from "../componets/DeptSalesChartQty";

export class DeptSales extends Component {
    render() {
        return (
            <>
                <div className="page-head">Department Report</div>
                <div className="p-grid p-fluid">
                    <div className="p-col-12">
                        <StoreContextConsumer>
                            {({ terminal, setTerminal, clerk, setClerk }) => {
                                return (
                                    <FilterComponent terminal={terminal} setTerminal={setTerminal} clerk={clerk} setClerk={setClerk} />
                                );
                            }}
                        </StoreContextConsumer>
                    </div>
                </div>

                <div className="p-grid p-fluid">
                    <div className="p-col-12 p-lg-6">
                        <StoreContextConsumer>
                            {({ currentStore, dateRange, setDateRange, clerk, terminal }) => {
                                return (
                                    <DeptSalesChart store={currentStore} dates={dateRange} clerk={clerk} terminal={terminal} />
                                );
                            }}

                        </StoreContextConsumer>
                    </div>

                    <div className="p-col-12 p-lg-6">
                        <StoreContextConsumer>
                            {({ currentStore, dateRange, setDateRange, clerk, terminal }) => {
                                return (
                                    <DeptSalesChartAmount store={currentStore} dates={dateRange} terminal={terminal} clerk={clerk} />
                                );
                            }}

                        </StoreContextConsumer>
                    </div>
                </div>

                <div className="p-grid p-fluid">
                    <div className="p-col-12">
                        <StoreContextConsumer>
                            {({ currentStore, dateRange, setDateRange, clerk, terminal }) => {
                                return (
                                    <DepartmentSalesReport store={currentStore} dates={dateRange} clerk={clerk} terminal={terminal} />
                                );
                            }}
                        </StoreContextConsumer>
                    </div>
                </div>
            </>
        );
    }
}
