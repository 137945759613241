import React from 'react';
import moment from 'moment'
import { Dropdown } from 'primereact/dropdown';
import { DateRange } from './DateRange';
import { StoreContextConsumer } from '../StoreContext';

export class DateSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCustom: false,
            dateRange: props.filterDate
        }
        this.onValueChange = this.onValueChange.bind(this);
    }

    onValueChange(e) {
        if (e.value.name === 'Custom') {
            this.setState({ showCustom: true });
        } else {
            this.setState({ showCustom: false })
        }
        let dateRange = []
        switch (e.value.name) {
            case 'Today':
                dateRange = [moment(), moment()]
                break;
            case 'Yesterday':
                dateRange = [moment().subtract(1, 'day'), moment().subtract(1, 'day')]
                break;
            case 'This Week':
                dateRange = [moment().startOf('isoWeek'), moment().endOf('isoWeek')]
                break;
            case 'Last Week':
                dateRange = [moment().subtract(1, 'weeks').startOf('isoWeek'),
                moment().subtract(1, 'weeks').endOf('isoWeek')]
                break;
            case 'This Month':
                dateRange = [moment().startOf('month'), moment().endOf('month')]
                break;
            case 'Last Month':
                dateRange = [moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month')]
                break;
            case 'This Quarter':
                dateRange = [moment().quarter(moment().quarter()).startOf('quarter'),
                moment().quarter(moment().quarter()).endOf('quarter')]
                break;
            case 'Last Quarter':
                dateRange = [moment().subtract(1, 'quarter').startOf('quarter'),
                moment().subtract(1, 'quarter').endOf('quarter')]
                break;
            default:
                break;
        }
        this.props.setDates(dateRange);
        this.props.setFilterDate(e.value);
        this.setState({ dateRange: e.value });
    }

    render() {
        const dateOptions = [
            { name: 'Today', code: 'Today' },
            { name: 'Yesterday', code: 'Yesterday' },
            { name: 'This Week', code: 'This Week' },
            { name: 'Last Week', code: 'Last Week' },
            { name: 'This Month', code: 'This Month' },
            { name: 'Last Month', code: 'Last Month' },
            { name: 'This Quarter', code: 'This Quarter' },
            { name: 'Last Quarter', code: 'Last Quarter' },
            { name: 'Custom', code: 'Custom' }
        ];

        return (
            <>
                <div style={{ marginBottom: '20px' }}>
                    <span style={{ color: 'black' }}>  Current date range </span>
                    <b> {moment(this.props.dates[0]).format('DD-MM-YYYY')}</b> to <b> {moment(this.props.dates[1]).format('DD-MM-YYYY')}</b>
                </div>

                <div className="p-grid p-fluid ">
                    <div className="p-col-12 p-lg-6 ">
                        <div style={{ height: '25px', color: "#999" }}>
                            <span style={{ color: 'black' }}>Select date range</span>
                        </div>
                        <Dropdown value={this.state.dateRange} options={dateOptions} onChange={this.onValueChange}
                            optionLabel="name" />
                    </div>

                    <div className="p-col-12 p-lg-6">
                        {this.state.showCustom &&
                            <StoreContextConsumer >
                                {({ currentStore, dateRange, setDateRange }) => {
                                    return <DateRange dates={dateRange} setDates={setDateRange} />;
                                }}
                            </StoreContextConsumer>
                        }
                    </div>
                </div>
            </>
        )
    }
}