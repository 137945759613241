import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import apiClient from "../apiClient";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from "primereact/card";

export class EposFileComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            first: 0,
            rows: 25,
            totalRecords: 25,
            file: 1,
            loading: true,
            data: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.onPage = this.onPage.bind(this)
    }

    onPage(event) {
        this.setState({ first: event.first, rows: event.rows })
    }

    handleChange(event) {
        this.setState({ file: event.target.value, first: 0 })
        event.preventDefault();
    }

    setTotalRecords() {
        let totalCount = this.props.totalCount.map((item => item.memory));
        switch (this.state.file) {
            case 1: this.setState({ totalRecords: totalCount[0] });
                break;
            case 2: this.setState({ totalRecords: totalCount[1] });
                break;
            case 3: this.setState({ totalRecords: totalCount[2] });
                break;
            case 4: this.setState({ totalRecords: totalCount[3] });
                break;
            case 5: this.setState({ totalRecords: totalCount[4] });
                break;
            case 6: this.setState({ totalRecords: totalCount[5] });
                break;
            case 21: this.setState({ totalRecords: totalCount[6] });
                break;
            case 25: this.setState({ totalRecords: totalCount[7] });
                break;
            case 34: this.setState({ totalRecords: totalCount[8] });
                break;
            case 26: this.setState({ totalRecords: totalCount[9] });
                break;
            case 27: this.setState({ totalRecords: totalCount[10] });
                break;
            case 111: this.setState({ totalRecords: totalCount[11] });
                break;
            default:
                break;
        }
    }

    fetchData(from, to) {
        this.setState({ loading: true });
        // file api
        apiClient
            .get(
                `/v1/get/eposfile/file=${this.state.file}/from_id=${from}/to_id=${to}`
            )
            .then(res => {
                this.setState({ data: res.data.values, loading: false });
                if (res.data.values.length === 0) {
                    this.setState({ first: 0 })
                }
            })
            .catch(err => {
                this.setState({ data: [], loading: false });
                console.log('Error fetching customer data: ', err)
            });
    }

    componentDidMount() {
        this.fetchData(this.state.first, this.state.first + this.state.rows)
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.props.store !== prevProps.store) || (this.state.file !== prevState.file)) {
            this.setTotalRecords()
            this.setState({ first: 0 })
            this.fetchData(0, this.state.rows)
        }
        if ((this.state.first !== prevState.first) || (this.state.rows !== prevState.rows)) {
            this.fetchData(this.state.first, this.state.first + this.state.rows)
        }
    }

    columm = {
        1: [{ field: 'id', header: 'ID' }, { field: 'name', header: 'Name' }],
        2: [{ field: 'id', header: 'ID' }, { field: 'name', header: 'Name' }],
        3: [{ field: 'id', header: 'ID' }, { field: 'name', header: 'Name' }],
        4: [{ field: 'id', header: 'ID' }, { field: 'name', header: 'Name' }],
        5: [{ field: 'id', header: 'ID' }, { field: 'name', header: 'Name' },
        { field: 'department_id', header: 'Department ID' }, { field: 'group_id', header: 'Group ID' },
        { field: 'price', header: 'Price' }, { field: 'tax_id', header: 'Tax ID' },
        { field: 'mixmatch_id', header: 'Mixmatch ID' },
        { field: 'description', header: 'Description ' }],
        6: [{ field: 'id', header: 'ID' }, { field: 'name', header: 'Name' }],
        21: [{ field: 'id', header: 'ID' }, { field: 'name', header: 'Name' },
        { field: 'rate', header: 'Rate' }, { field: 'caltype', header: 'Caltype' },
        { field: 'roundingmethod', header: 'Roundingmethod' }],
        25: [{ field: 'id', header: 'ID' }, { field: 'name', header: 'Name' }],
        34: [{ field: 'id', header: 'ID' }, { field: 'name', header: 'Name' }],
        26: [{ field: 'id', header: 'ID' }, { field: 'keycode', header: 'Keycode' },
        { field: 'fname', header: 'First Name' }, { field: 'sname', header: 'Sur Name' },
        { field: 'address1', header: 'Address1' }, { field: 'address2', header: 'Address2' },
        { field: 'address3', header: 'Address3' }, { field: 'postcode', header: 'Postcode ' },
        { field: 'telephone', header: 'Telephone' }, { field: 'email', header: 'Email' },
        { field: 'overdraftlimit', header: 'Overdraftlimit' }, { field: 'custgroup_id', header: 'Custgroup ID ' }],
        27: [{ field: 'id', header: 'ID' }, { field: 'name', header: 'Name' },
        { field: 'loyaltytype', header: 'Loyaltytype' }, { field: 'accountcustomer', header: 'Account Customer' },
        { field: 'pointsperpound', header: 'Points Per Pound' }, { field: 'pointsrequired', header: 'Points Required' },
        { field: 'allrate', header: 'Allrate' }, { field: 'selectiveitems1rate', header: 'Selective Items1 Rate' },
        { field: 'selectiveitems2rate', header: 'Selective Items2 Rate' }, { field: 'selectiveitems3rate', header: 'Selective Items3 Rate' },
        { field: 'selectiveitems4rate', header: 'Selective Items4 Rate' }, { field: 'priceshift', header: 'Price Shift ' },
        { field: 'pointsselectiveitems', header: 'Points Selective Items ' }],
        111: [{ field: 'id', header: 'ID' }, { field: 'name', header: 'Name' },
        { field: 'price', header: 'Price' }, { field: 'department_id', header: 'Department ID' },
        { field: 'group_id', header: 'Group ID' }, { field: 'qty', header: 'QTY' },
        { field: 'tax_id', header: 'Tax ID ' },]
    }

    fileOptions = [
        { label: 'Fixed totalizer', value: 1 },
        { label: 'Free function', value: 2 },
        { label: 'Department', value: 3 },
        { label: 'PLU Group', value: 4 },
        { label: 'PLU', value: 5 },
        { label: 'Clerk', value: 6 },
        { label: 'Tax', value: 21 },
        { label: 'Mix & Match', value: 25 },
        { label: 'Mix & Match 2', value: 34 },
        { label: 'Customer', value: 26 },
        { label: 'Customer Group', value: 27 },
        { label: 'PLU 2nd', value: 111 }
    ];

    render() {
        const find = this.fileOptions.find((item) => item.value === this.state.file)
        const heading = find ? find.label : ''
        return (
            <>
                <div className="page-head">EPOS Files</div>
                <div className="p-grid p-fluid">
                    <div className="p-col-12 p-lg-4">
                        <div className="p-grid">
                            <div className="p-col-4 p-lg-4 p-col-align-center">
                                <div style={{ height: '25px', color: "#999" }}>
                                    <label htmlFor="File" style={{ color: 'black' }}>Select File</label>
                                </div>
                            </div>
                            <div className="p-col-8 p-lg-8 p-col-align-center">
                                <Dropdown value={this.state.file} options={this.fileOptions} onChange={this.handleChange} name="file" />
                            </div>
                        </div>
                    </div>
                </div>

                <Card title={heading} style={{ marginTop: "25px" }}>
                    {this.state.loading ? (
                        <div>Loading...</div>
                    ) : (
                            <div>
                                <div> Showing ID from <b>{this.state.first}</b> to <b>{this.state.first + this.state.rows}</b> </div>
                                <div style={{ textAlign: 'right', marginBottom: '10px' }}>
                                    <span style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }} onClick={(e) => this.dataTable.exportCSV()}>Download CSV</span>
                                </div>
                                <DataTable ref={(el) => { this.dataTable = el; }} value={this.state.data}
                                    totalRecords={this.state.totalRecords} lazy={true} first={this.state.first}
                                    onPage={this.onPage} loading={this.state.loading} responsive={true}
                                    scrollable={true} paginator={true} rows={this.state.rows}
                                    rowsPerPageOptions={[25, 50, 100, 200]} style={{ width: '100%' }}
                                    resizableColumns={true} columnResizeMode="fit" paginatorPosition='both'>
                                    {this.columm[this.state.file].map((item, i) => {
                                        return <Column key={item.field} field={item.field} header={item.header} style={{ width: '200px' }} />;
                                    })}
                                </DataTable>
                            </div>
                        )}
                </Card>
            </>
        )
    }
} 