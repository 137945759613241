import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
//import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import { Login } from "./pages/Login";
import ProtectedRoute from "./ProtectedRoute";
import { UserContextProvider } from "./UserContext";
import { StoreContextProvider } from "./StoreContext";

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop>
      <UserContextProvider>
        <StoreContextProvider>
          <Switch>
            <Route path="/login" component={Login} />
            <ProtectedRoute path="/" component={App} />
          </Switch>
        </StoreContextProvider>
      </UserContextProvider>
    </ScrollToTop>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
