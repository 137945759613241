import React from "react";
import apiClient from "./apiClient";
import moment from 'moment'
const StoreContext = React.createContext();

class StoreContextProvider extends React.Component {
  constructor(props) {
    super(props);
    let date = moment();
    this.state = {
      currentStore: null,
      dateRange: [date, date],
      filterDate: { name: 'Today', code: 'Today' },
      clerk: { id: 0, name: "All Clerk" },
      terminal: { name: 'All Terminal', code: 0 },
      clerkOptions: [],
      totalCount: [],
    };

    this.setCurrentStore = this.setCurrentStore.bind(this);
    this.clearCurrentStore = this.clearCurrentStore.bind(this);
    this.setDateRange = this.setDateRange.bind(this);
    this.setFilterDate = this.setFilterDate.bind(this);
    this.setClerk = this.setClerk.bind(this);
    this.setTerminal = this.setTerminal.bind(this);
    this.setClerkOptions = this.setClerkOptions.bind(this);
    this.setFileSize = this.setFileSize.bind(this);
  }

  setCurrentStore(user, store) {
    apiClient
      .get(`v1/renew/apikey/userid=${user.id}/store=${store.name}`)
      .then(res => {
        if (res.data.result === 0) {
          localStorage.setItem("access", res.data.apikey);
        } else {
          localStorage.removeItem("access");
        }
        return this.setClerkOptions()
      })
      .then(res => {
        return this.setFileSize()
      })
      .then(res => {
        this.setState({ currentStore: store });
      })
      .catch(err => {
        console.log('Error setting store: ', err)
      })

  }

  clearCurrentStore(){
    this.setState({currentStore: null})
  }

  setClerkOptions() {
    return apiClient
      .get('v1/get/eposfile/file=6')
      .then(res => {
        let options = res.data.values.filter(item => item.name.length > 0)
        options.splice(0, 0, { id: 0, name: "All Clerk" })
        this.setState({ clerkOptions: options })
      })
      .catch(err => {
        console.log('Error setting clerkOptions: ', err)
      })
  }
  setFileSize() {
    return apiClient.get(
      `/v1/utils/get/memory`
    )
      .then(res => {
        this.setState({ totalCount: res.data.values, loading: false });
      })
      .catch(err => {
        this.setState({ loading: false });
        console.log('Error setting store: ', err)
      });
  }


  setDateRange(dates) {
    this.setState({ dateRange: dates });
  }

  setFilterDate(val) {
    this.setState({ filterDate: val });
  }

  setClerk(clerk) {
    this.setState({ clerk: clerk });
  }

  setTerminal(terminal) {
    this.setState({ terminal: terminal });
  }

 
  render() {
    return (
      <StoreContext.Provider
        value={{
          currentStore: this.state.currentStore,
          setCurrentStore: this.setCurrentStore,
          clearCurrentStore: this.clearCurrentStore,
          dateRange: this.state.dateRange,
          setDateRange: this.setDateRange,
          filterDate: this.state.filterDate,
          setFilterDate: this.setFilterDate,
          clerk: this.state.clerk,
          setClerk: this.setClerk,
          terminal: this.state.terminal,
          setTerminal: this.setTerminal,
          clerkOptions: this.state.clerkOptions,
          totalCount: this.state.totalCount,
        }}
      >
        {this.props.children}
      </StoreContext.Provider>
    );
  }
}

const StoreContextConsumer = StoreContext.Consumer;

export { StoreContext, StoreContextProvider, StoreContextConsumer };
