import React from "react";
import apiClient from "../apiClient";
import { Chart } from "primereact/chart";
import { Card } from "primereact/card";
import moment from 'moment'

export class HourSalesGrossChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: false

        };
    }

    fetchData() {
        this.setState({ loading: true });
        const fromDate = moment(this.props.dates[0]).format('YYYY-MM-DD')
        const toDate = moment(this.props.dates[1]).format('YYYY-MM-DD')
        const clerkId = this.props.clerk.id;
        const terminalId = this.props.terminal.code;
        // HourSalesReportApi
        apiClient
            .get(
                `/v1/report/hourlysales/clerk=${clerkId}/terminal=${terminalId}/from_date=${fromDate}/to_date=${toDate}`
            )
            .then(res => {
                this.setState({ data: res.data.values, loading: false });
            })
            .catch(err => {
                this.setState({ data: [], loading: false });
                console.log('Error fetching GroupSales data: ', err)
            });
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if ((this.props.dates[1] == null) || (this.props.store == null)) return;
        if ((this.props.dates !== prevProps.dates) || (this.props.store !== prevProps.store) || (this.props.clerk !== prevProps.clerk) || (this.props.terminal !== prevProps.terminal)) {
            this.fetchData();
        }
    }

    render() {
        const item = this.state.data.map(item => item.gross[1].toFixed(2))
        const amt = {
            labels: ['00', '01', ' 02', ' 03', '04', '05', '06', '07', ' 08', ' 09', ' 10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', ' 23'],
            datasets: [
                {
                    data: item,
                    label: 'Gross Amount',
                    fill: false,
                    backgroundColor: '#42A5F5 ',
                    borderColor: '#42A5F5'
                },
            ]
        };

        const amtOptions = {
            maintainAspectRatio: false,
            aspectRatio: 1,
            title: {
                display: true,
                text: "Hourly  Gross Sales",
                fontSize: 16
            },
            legend: {
                position: "right",
                display: false
            },
            scales: {
                xAxes: [{
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: 'Hour'
                    }
                }],
                yAxes: [{
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: 'Gross Amount'
                    }
                }]
            }
        };

        return (
            <>
                <Card style={{ marginTop: "25px" }}>
                    {this.state.loading ? (
                        <div>Loading...</div>
                    ) : item.every(i => i === 0.) || this.state.data.length === 0 ? (
                        <div>No data available, select different date range or store</div>
                    ) : (
                                <Chart type="bar" data={amt} options={amtOptions}  />
                            )}
                </Card>
            </>
        );
    }
}

