import React from "react";
import apiClient from "../apiClient";
import { Card } from "primereact/card";
import moment from 'moment'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { OrderLineSalesReport } from "./OrderLineSalesReport";
import { StoreContextConsumer } from "../StoreContext";
import { withRouter } from 'react-router-dom';

class OrderSalesByDateReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            visible: false,
            loading: false,
            orderId: null,
            rows: 10

        };
        this.onRowClickChange = this.onRowClickChange.bind(this);
        this.onHide = this.onHide.bind(this);
        this.rowHighLight = this.rowHighLight.bind(this);
    }

    onRowClickChange(e) {
        this.setState({ visible: true });
        this.setState({ orderId: e.data.id });
    }

    rowHighLight(rowData) {
        return { 'p-highlight': (rowData.id === this.state.orderId) };
    }

    onHide() {
        this.setState({ visible: false });
    }

    fetchData() {
        this.setState({ loading: true });
        const fromDate = moment(this.props.dates[0]).format('YYYY-MM-DD')
        const toDate = moment(this.props.dates[1]).format('YYYY-MM-DD')
        const clerkId = this.props.clerk.id;
        const terminalId = this.props.terminal.code;
        const custId = this.props.match.params.cid ? this.props.match.params.cid : 0
        apiClient
            .get(
                // orderSalesReport api
                `v1/get/orders/clerk=${clerkId}/terminal=${terminalId}/customer=${custId}/date_from=${fromDate}/date_to=${toDate}/limit_rec=0/limit_row=1000/sortorder=desc`,
            )
            .then(res => {
                this.setState({ data: res.data.values, loading: false });
            })
            .catch(err => {
                this.setState({ data: [], loading: false });
                console.log('Error on fetchData: ', err)
            })
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if ((this.props.dates[1] == null) || (this.props.store == null)) return;
        if ((this.props.match.params.cid !== prevProps.match.params.cid) || (this.props.dates !== prevProps.dates) || (this.props.store !== prevProps.store) || (this.props.clerk !== prevProps.clerk) || (this.props.terminal !== prevProps.terminal)) {
            this.fetchData();
        }
    }
    dateTemplate(rowData, column) {
        return <span>{rowData.cdate}</span>;
        return <span>{moment(rowData.date).format('DD-MM-YYYY')}</span>;
    }
    amountTemplate(rowData, column) {
        return <span>{Number(rowData.total).toFixed(2)}</span>;
    }

    timeTemplate(rowData, column) {
        return <span>{moment(rowData.time, 'HH:mm:ss').format('HH:mm')}</span>;
    }

    statusTemplate(rowData, column) {
        const status = rowData.status === 0 ? 'Hold' : rowData.status === 1 ? 'Paid' : 'Unassigned'
        return <span>{status}</span>
    }

    render() {
        const orderid = this.state.orderId
        const header = <span >Transaction details of Order {orderid}</span>
        return (

            <Card title="Transactions" style={{ marginTop: "25px" }}>
                {this.state.loading ? (
                    <div>Loading...</div>
                ) : this.state.data.length === 0 ? (
                    <div>No data available, select different date range or store</div>
                ) : (
                    <div>
                        <div style={{ textAlign: 'right', marginBottom: '10px' }}>
                            <span style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }} onClick={(e) => this.dataTable.exportCSV()}>Download CSV</span>
                        </div>
                        <DataTable ref={(el) => { this.dataTable = el; }} value={this.state.data} onRowClick={this.onRowClickChange} rowClassName={this.rowHighLight} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 20, 50, 100]} responsive={true} style={{ textAlign: 'center' }} >
                            <Column field="id" header="ID" />
                            <Column header="Date" body={this.dateTemplate} />
                            <Column header="Time" body={this.timeTemplate} />
                            <Column field="terminal_id" header="Terminal No." />
                            <Column field="consno" header="Cons No." />
                            <Column field="tableno" header="Tbl/Tkt No." />
                            <Column field="clerk_id" header="Clerk ID" />
                            <Column field="customer_id" header="Customer" />
                            <Column field="total" header="Total" style={{ textAlign: 'right' }} body={this.amountTemplate} />
                            <Column field="status" header="Status" body={this.statusTemplate} />
                        </DataTable>
                    </div>
                )}

                <Dialog header={header} visible={this.state.visible} style={{ width: '90vw' }} onHide={this.onHide} blockScroll>
                    <StoreContextConsumer>
                        {({ currentStore, dateRange, terminal, clerk }) => {
                            return (
                                this.state.orderId && <OrderLineSalesReport orderId={this.state.orderId} terminal={terminal} clerk={clerk} />
                            );
                        }}
                    </StoreContextConsumer>
                </Dialog>
            </Card >
        );
    }
}
export default withRouter(OrderSalesByDateReport) 