import axios from 'axios';

const apiClient = axios.create({
        baseURL: process.env.REACT_APP_MAIN_API_BASE_URL,
        timeout: 150000
    }
);


async function getAccessToken() {
    return localStorage.getItem('access');
}

// Request interceptor for auth header
apiClient.interceptors.request.use(
    async (config) => {
        const token = await getAccessToken(); 
        if (token) config.headers.Authorization = `${token}`;
        return config;
    },
    (error) => {
        return Promise.reject (error);
    }
);


export default apiClient;