import React, { Component } from 'react';
import classNames from 'classnames';
import { AppTopbar } from './AppTopbar';
import { AppMenu } from './AppMenu';
import { Route, Switch } from 'react-router-dom';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './layout/layout.scss';
import { Dashboard } from './pages/Dashboard';
import { PluSalesPage } from './pages/PluSalesPage';
import { BestSellersPage } from './pages/BestSellersPage';
import { GroupSalesPage } from './pages/GroupSalesPage'
import { DeptSales } from './pages/DeptSales';
import { FullFixedPage } from './pages/FullFixedPage';
import { HourSalesReportPage } from './pages/HourSalesReportPage';
import { OrderSalesByDatePage } from './pages/OrderSalesByDatePage';
import { About } from './pages/About';
import { YourAccount } from './pages/YourAccount';
import { CustomerReportPage } from './pages/CustomerReportPage';
import { CustomerTransactionReport } from './pages/CustomerTransactionReport';
import { EposFilePage } from './pages/EposFilePage';
import { MixAndMatchPage } from './pages/MixAndMatchPage';
import { MixAndMatch2Page } from './pages/MixAndMatch2Page';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            layoutMode: 'static',
            layoutColorMode: 'dark',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false
        };

        this.onWrapperClick = this.onWrapperClick.bind(this);
        this.onToggleMenu = this.onToggleMenu.bind(this);
        this.onSidebarClick = this.onSidebarClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.createMenu();
    }

    onWrapperClick() {
        if (!this.menuClick) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            });
        }
        this.menuClick = false;
    }

    onToggleMenu(event) {
        this.menuClick = true;

        if (this.isDesktop()) {
            if (this.state.layoutMode === 'overlay') {
                this.setState({
                    overlayMenuActive: !this.state.overlayMenuActive
                });
            }
            else if (this.state.layoutMode === 'static') {
                this.setState({
                    staticMenuInactive: !this.state.staticMenuInactive
                });
            }
        }
        else {
            const mobileMenuActive = this.state.mobileMenuActive;
            this.setState({
                mobileMenuActive: !mobileMenuActive
            });
        }
        event.preventDefault();
    }

    onSidebarClick() {
        this.menuClick = true;
    }

    onMenuItemClick(event) {
        if (!event.item.items) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            })
        }
    }

    createMenu() {
        this.menu = [
            { label: 'Dashboard ', icon: 'pi pi-fw pi-home', command: () => { this.props.history.push('/') } },
            { label: 'Transactions', icon: 'pi pi-fw pi-table', command: () => { this.props.history.push('/transactions/0') } },
            { label: 'Financial Report', icon: 'pi pi-fw pi-table', command: () => { this.props.history.push('/financialreport') } },
            { label: 'Hourly Sales Report', icon: 'pi pi-fw pi-table', command: () => { this.props.history.push('/hourlysalesreport') } },
            { label: 'Department Report', icon: 'pi pi-fw pi-table', command: () => { this.props.history.push('/departmentreport') } },
            { label: 'Group Report', icon: 'pi pi-fw pi-table', command: () => { this.props.history.push('/groupreport') } },
            { label: 'PLU Report', icon: 'pi pi-fw pi-table', command: () => { this.props.history.push('/plureport') } },
            { label: 'Best Sellers', icon: 'pi pi-fw pi-table', command: () => { this.props.history.push('/bestsellers') } },
            { label: 'Mix & Match Report', icon: 'pi pi-fw pi-table', command: () => { this.props.history.push('/mixmatch1') } },
            { label: 'Mix & Match 2 Report', icon: 'pi pi-fw pi-table', command: () => { this.props.history.push('/mixmatch2') } },
            { label: 'Customer Report', icon: 'pi pi-fw pi-table', command: () => { this.props.history.push('/customerreport') } },
            { label: 'Customer Transactions', icon: 'pi pi-fw pi-table', command: () => { this.props.history.push('/customertransreport') } },
            { label: 'EPOS Files', icon: 'pi pi-fw pi-file', command: () => { this.props.history.push('/eposfiles') } },
            { label: 'Your Account', icon: 'pi pi-fw pi-user', command: () => { this.props.history.push('/youraccount') } },
            { label: 'About', icon: 'pi pi-fw pi-info', command: () => { this.props.history.push('/about') } },
        ];
    }

    addClass(element, className) {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    removeClass(element, className) {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    componentDidUpdate() {
        if (this.state.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    }

    render() {
        const wrapperClass = classNames('layout-wrapper', {
            'layout-overlay': this.state.layoutMode === 'overlay',
            'layout-static': this.state.layoutMode === 'static',
            'layout-static-sidebar-inactive': this.state.staticMenuInactive && this.state.layoutMode === 'static',
            'layout-overlay-sidebar-active': this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
            'layout-mobile-sidebar-active': this.state.mobileMenuActive
        });

        const sidebarClassName = classNames("layout-sidebar", {
            'layout-sidebar-dark': this.state.layoutColorMode === 'dark',
            'layout-sidebar-light': this.state.layoutColorMode === 'light'
        });

        return (
            <div className={wrapperClass} onClick={this.onWrapperClick}>
                <AppTopbar onToggleMenu={this.onToggleMenu} />

                <div ref={(el) => this.sidebar = el} className={sidebarClassName} onClick={this.onSidebarClick}>
                    <div className="layout-logo">
                        <div className="logo-text"><span style={{ color: 'red' }}>ECTouch </span> <span style={{ color: '#ccc' }}> Office </span></div>
                    </div>
                    <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick} />
                </div>

                <div className="layout-main">
                    <Switch>
                        <Route path="/" exact component={Dashboard} />
                        <Route path="/transactions/:cid" component={OrderSalesByDatePage} />
                        <Route path="/plureport" exact component={PluSalesPage} />
                        <Route path="/bestsellers" exact component={BestSellersPage} />
                        <Route path="/groupreport" exact component={GroupSalesPage} />
                        <Route path="/departmentreport" component={DeptSales} />
                        <Route path="/financialreport" component={FullFixedPage} />
                        <Route path="/hourlysalesreport" component={HourSalesReportPage} />
                        <Route path="/customerreport" component={CustomerReportPage} />
                        <Route path="/customertransreport" component={CustomerTransactionReport} />
                        <Route path="/eposfiles" component={EposFilePage} />
                        <Route path="/youraccount" component={YourAccount} />
                        <Route path="/about" component={About} />
                        <Route path="/mixmatch1" component={MixAndMatchPage} />
                        <Route path="/mixmatch2" component={MixAndMatch2Page} />
                        <Route path="/empty" />
                    </Switch>
                </div>
                <div className="layout-mask"></div>
            </div>
        );
    }
}

export default App;
