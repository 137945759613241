import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import {UserContextConsumer} from './UserContext'
import AutoLogin from "./AutoLogin";

const ProtectedRoute = ({component: Component, ...rest}) => (
    <UserContextConsumer>
        {({isAuthenticated, loginWithRefreshToken}) => (
            <AutoLogin authStatus={isAuthenticated} loginHandler={loginWithRefreshToken}>
                <Route
                    render={props =>
                        isAuthenticated ? <Component {...props} /> : <Redirect to={{
                            pathname: "/login",
                            state: {from: props.location}
                        }}/>
                    }
                    {...rest}
                />
            </AutoLogin>
        )}
    </UserContextConsumer>
);

export default ProtectedRoute