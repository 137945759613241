import React, { Component } from "react";
import PropTypes from "prop-types";
import { UserContextConsumer } from "./UserContext";
import { StoreContextConsumer } from "./StoreContext";
import { Dropdown } from "primereact/dropdown";

export class AppTopbar extends Component {
  static defaultProps = {
    onToggleMenu: null
  };

  static propTypes = {
    onToggleMenu: PropTypes.func.isRequired
  };

  render() {
    return (
      <UserContextConsumer>
        {({ isAuthenticated, user, logout }) => {
          return (
            <>
              <StoreContextConsumer>
                {({ currentStore, setCurrentStore, clearCurrentStore }) => {
                  if (currentStore == null && user.storelist.stores.length > 0) {
                    setCurrentStore(user, user.storelist.stores[0]);
                  }
                  return (
                    <div className="layout-topbar clearfix">
                      <button
                        className="p-link layout-menu-button"
                        onClick={this.props.onToggleMenu}
                      >
                        <span className="pi pi-bars" />
                      </button>
                      <div className="store-select">
                        Store &nbsp;
                        <Dropdown
                          optionLabel="name"
                          value={currentStore}
                          options={user.storelist.stores}
                          onChange={e => {
                            setCurrentStore(user, e.value);
                          }}
                        />
                      </div>
                      <div className="layout-topbar-icons">
                        <span className="username">{user && user.fname}</span>
                        <button className="p-link" onClick={(e)=>{logout(); clearCurrentStore()}} title={"Logout"}>
                          <span className="layout-topbar-icon pi pi-power-off" />
                        </button>
                      </div>
                    </div>
                  )
                }}
              </StoreContextConsumer>
            </>
          );
        }}
      </UserContextConsumer>
    );
  }
}
