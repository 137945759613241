import React from "react";
import { FilterComponent } from "../componets/FilterComponent";
import { StoreContextConsumer } from "../StoreContext";
import OrderSalesByDateReport  from "../componets/OrderSalesByDateReport";

export class OrderSalesByDatePage extends React.Component {
    render() {
        return (
            <>
                <div className="page-head">Transactions {this.props.match.params.cid !=0 && `of Customer ${this.props.location.state.custName}`} </div>
                <div className="p-grid p-fluid">

                    <div className="p-col-12 ">
                        <StoreContextConsumer>
                            {({ terminal, setTerminal, clerk, setClerk }) => {
                                return (
                                    <FilterComponent terminal={terminal} setTerminal={setTerminal} clerk={clerk} setClerk={setClerk} />
                                );
                            }}
                        </StoreContextConsumer>
                    </div>

                    <div className="p-col-12 ">
                        <StoreContextConsumer>
                            {({ currentStore, dateRange, setDateRange, clerk, setClerk ,terminal}) => {
                                return (
                                    <OrderSalesByDateReport store={currentStore} dates={dateRange} terminal={terminal} clerk={clerk} setClerk={setClerk} />
                                );
                            }}
                        </StoreContextConsumer>
                    </div>

                </div>
            </>
        );
    }
}
