import React from 'react';
import { StoreContextConsumer } from "../StoreContext";
import { EposFileComponent } from "../componets/EposFileComponent"

export class EposFilePage extends React.Component {
    render() {
        return (
            <div className="p-col-12">
                <StoreContextConsumer>
                    {({ currentStore, dateRange, clerk, terminal, totalCount }) => {
                        return (
                            currentStore && <EposFileComponent store={currentStore} dates={dateRange} clerk={clerk} terminal={terminal} totalCount={totalCount} />
                        );
                    }}
                </StoreContextConsumer>
            </div>

        )
    }


} 