import React from "react";
import { Card } from 'primereact/card';
import { UserContextConsumer } from "../UserContext";

export class YourAccount extends React.Component {
  render() {
    return (
      <>
        <div className="page-head">Your Account</div>

        <UserContextConsumer>
          {({ user }) => {
            const storeList = user.storelist.stores;
            return (
              <div className="p-grid">
                <div className="p-col-12 p-md-6 p-lg-6">
                  <Card >
                    <div className="p-grid">
                      <div className="p-col-6" style={{ fontWeight: "bold", marginTop: '.1em' }}>First Name :</div>
                      <div className="p-col-6">{user.fname}</div>
                      <div className="p-col-6" style={{ fontWeight: "bold", marginTop: '.1em' }}>Surname :</div>
                      <div className="p-col-6">{user.sname}</div>
                      <div className="p-col-6" style={{ fontWeight: "bold", marginTop: '.1em' }}>E-Mail :</div>
                      <div className="p-col-6">{user.email}</div>
                      <div className="p-col-6" style={{ fontWeight: "bold", marginTop: '.1em' }}>Telephone :</div>
                      <div className="p-col-6">{user.telephone}</div>
                    </div>
                  </Card>
                </div>

                <div className="p-col-12 p-md-6 p-lg-6">
                  <Card subTitle="Store Details">
                    <div className="p-datatable p-component">
                      <div className="p-datatable-wrapper">
                        <table role="grid">
                          <thead className="p-datatable-thead">
                            <tr>
                              <th>Store ID</th>
                              <th >Store Name</th>
                              <th >Terminals</th>
                            </tr>
                          </thead>
                          <tbody className="p-datatable-tbody" margintop='.2em'>
                            {
                              storeList.map((item, key) => (
                                <tr key={item.id}>
                                  <td >{item.id}</td>
                                  <td >{item.name}</td>
                                  <td >{item.terminals}</td>
                                </tr>))
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            );
          }}
        </UserContextConsumer>
      </>
    );
  }
}








